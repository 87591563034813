import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
//modules
import Messages from './modules/Messages'
import OffersApplications from './modules/OffersApplications'
import Calendar from './modules/Calendar'

import {authAgentOSSignIn, authAgentOSSignOut} from './agentosAuth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Messages,
    OffersApplications,
    Calendar
  },
  state: {
    // apiKey: 'yrR5n2TUHYH44ybHgYhGdre5k-ze6fUwLI7UTNzKjuI1', // dev
     apiKey: 'JDVFKQYjqyo2ToBolbDTKSnqmWnr_QsRi-4zzGWC9s41', //live
    user: '',
    sessionData: '',
    appointmentTypes: [],
    appointments: [],
    today: new Date(),
    fetchinAppointments: false,
    myLettingProperties: '',
    propertyTypes: '',
    // For distinguishing between property types when fetching images in _blank carousel page
    selectedPropertyType: '',
    date: new Date(),
    date2: new Date(),
    // Messaging pages
    loggedInUserEmailAccounts: '',
    loggedInUserSmsAccounts: '',
    onlineApplications: '',
    staffEmails: '',
    branchEmails: '',
    branchSms: '',
    sentMessages: '',
    loginRegistrations: '',
    salesProperties: '',
    lettingsProperties: '',
    config: '',
    selectedColleagueDiary: ''
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData
      state.user["loginTime"] = Date.now();
      localStorage.setItem('user', JSON.stringify(userData))
      state.config = {
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey,
        }
      }
      // axios.defaults.headers.common['Authorization'] = `Bearer ${userData.LoginToken}`
      authAgentOSSignIn(userData.UserOID, userData.UserDisplayName, userData.UserToken);
    },
    SET_SESSION_DATA(state, data) {
      state.sessionData = data
    },
    EXPIRE_LOGIN(state){
      const then = new Date(state.user.loginTime)
      const now = new Date();

      const msBetweenDates = Math.abs(then.getTime() - now.getTime());

      // 👇️ convert ms to hours                  min  sec   ms
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
      console.log(hoursBetweenDates);
      if (hoursBetweenDates > 23) {
        state.user = '';
        localStorage.setItem('user', '');
      }
    },
    CLEAR_USER_DATA(state) {
      state.user = ''
      state.sessionData = ''
      state.appointmentTypes = []
      state.appointments = []
      state.myLettingProperties = ''
      state.propertyTypes = ''
      localStorage.removeItem('user')
      state.config = ''
      // axios.defaults.headers.common['Authorization'] = null
      // Messages pages
      state.loggedInUserEmailAccounts = ''
      state.loggedInUserSmsAccounts = ''
      state.onlineApplications = ''
      state.staffEmails = ''
      state.branchEmails = ''
      state.branchSms = ''
      state.sentMessages = ''
      state.loginRegistrations = ''
      state.salesProperties = ''
      state.lettingsProperties = ''
      state.selectedColleagueDiary = ''
      state.Calendar.allDiaries = ''

      authAgentOSSignOut();
    },
    SET_APPOINTMENTS(state, data) {
      state.appointments = data
    },
    SET_COLLEAGUES_APPOINTMENTS(state, data) { // pushes colleagues diary onto appointments array
      state.appointments.push(...data)
    },
    SET_COLLEAGUES_DIARY(state, data) { // sets colleagues diary name in $state
      state.selectedColleagueDiary = data
    },
    CLEAR_COLLEAGUE_DIARY(state) {
      state.selectedColleagueDiary = ''
    },
    SET_APPOINTMENT_TYPES(state, data) {
      state.appointmentTypes = data
    },
    SET_MY_LETTING_PROPERTIES(state, data) {
      state.myLettingProperties = data
    },
    SET_PROPERTY_TYPES(state, data) {
      state.propertyTypes = data
    },
    SET_PROPERTY_TYPE(state, data) {
      state.selectedPropertyType = data
    },
    // Messages pages
    SET_LOGGED_IN_USER_EMAIL_ACCOUNTS(state, data) {
      state.loggedInUserEmailAccounts = data
    },
    SET_LOGGED_IN_USER_SMS_ACCOUNTS(state, data) {
      state.loggedInUserSmsAccounts = data
    },
    SET_ONLINE_APPLICATIONS(state, data) {
      state.onlineApplications = data
    },
    SET_SALES_PROPERTIES(state, data) {
      state.salesProperties = data
    },
    SET_STAFF_EMAILS(state, data) {
      state.staffEmails = data
    },
    SET_BRANCH_EMAILS(state, data) {
      state.branchEmails = data
    },
    SET_BRANCH_SMS(state, data) {
      state.branchSms = data
    },
    SET_SENT_MESSAGES(state, data) {
      state.sentMessages = data
    },
    SET_LOGIN_REGISTRATIONS(state, data) {
      state.loginRegistrations = data
    },
    SET_LETTINGS_PROPERTIES(state, data) {
      state.lettingsProperties = data
    }
  },
  actions: {
    login({ commit, state }, credentials) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/session/login`,
        data: {
          username: credentials.username,
          password: credentials.password,
        },
        headers: {
          api_key: state.apiKey,
        },
      }).then(({ data }) => {
        commit('SET_USER_DATA', data)
      })
    },
    loginAgentOSSessionID({commit, dispatch, state}, credentials) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/session/loginsessionid`,
        data: {
          ShortName: credentials.shortName,
          SessionID: credentials.sessionID,
        },
        headers: {
        //  api_key: credentials.api_key
         api_key: state.apiKey
        },
      })
      .then(({data}) => {
        commit('SET_USER_DATA', data)
        dispatch('sessionStatus')
      })
      
    },
    logout({ commit, state }) {
      return axios.delete(`/corporate/agentapp/letmcletting/${state.user.ClientName}/session/logout`, state.config)
      .then(() => {
        commit('CLEAR_USER_DATA')
      })
    },
    sessionStatus({ state, commit, dispatch }) {
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/session/status`, state.config)
        .then(({ data }) => {
          commit('SET_SESSION_DATA', data)
        })
        .catch(() => {
          dispatch('logout')
        })
    },
    fetchAppointmentTypes({ commit, state }) {
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/appointments/types/0/100`, state.config)
        .then(({ data }) => {
          commit('SET_APPOINTMENT_TYPES', data.Data)
         })
    },
    fetchAppointments({ state, commit }) {
      //setting date range for appointments
      let date = state.date
      date.setDate(date.getDate() - 30)
      let pastDate = date.toISOString().substr(0, 10)

      let date2 = state.date2
      date2.setDate(date2.getDate() + 30)
      let futureDate = date2.toISOString().substr(0, 10)

      state.fetchinAppointments = true // for laoder

      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/diary/appointments?startDate=${pastDate}&endDate=${futureDate}`, state.config)
        .then(({ data }) => {
          state.fetchinAppointments = false
          let newArray = data.map((obj) => {
            // Deconstructing array objects
            // Start, End & to loweCase for Calendar props
            // Subject changed to name for Calendar props
            return {
              ...obj,
              end: obj.End,
              start: obj.Start,
              name: obj.Subject,
              myCalendar: true
            }
          })
          commit('SET_APPOINTMENTS', newArray) 
          // if (state.selectedColleagueDiary.length) { //check if any colleague diaries are stored in store
          //   const diaries = state.selectedColleagueDiary
          //   diaries.forEach(diary => {
          //     dispatch('Calendar/getColleaguesDiary', diary) // call diaries and add to appointments
          //   })
          // }
        })
    },
    fetchAppointmentWithDates({state, commit}, filterDates){
            //setting date range for appointments
            let pastDate = filterDates.start
            let futureDate = filterDates.end
            state.fetchinAppointments = true // for laoder
      
            return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/diary/appointments?startDate=${pastDate}&endDate=${futureDate}`, state.config)
              .then(({ data }) => {
                state.fetchinAppointments = false
                let newArray = data.map((obj) => {
                  // Deconstructing array objects
                  // Start, End & to loweCase for Calendar props
                  // Subject changed to name for Calendar props
                  return {
                    ...obj,
                    end: obj.End,
                    start: obj.Start,
                    name: obj.Subject,
                    myCalendar: true
                  }
                })
                commit('SET_APPOINTMENTS', newArray) 
                // if (state.selectedColleagueDiary.length) { //check if any colleague diaries are stored in store
                //   const diaries = state.selectedColleagueDiary
                //   diaries.forEach(diary => {
                //     dispatch('Calendar/getColleaguesDiary', diary) // call diaries and add to appointments
                //   })
                // }
              })
    },
    createAppointment({ state }, appointment) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${state.user.ClientName}/appointments/appointment/create`,
        data: appointment,
        headers: state.config.headers
      })
    },
    deleteAppointment({ state }, id) {
      return axios({
        method: 'DELETE',
        url: `/corporate/agentapp/letmcletting/${state.user.ClientName}/appointments/appointment/delete?appointmentID=${id}`,
        headers: state.config.headers
      })
    },
    getPersonInfo({ state }, id) {
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/contacts/people/${id}`, state.config)
    },
    addPropertyFeedback({ state }, payload) {
      return axios({
        method: 'PUT',
        url: `/corporate/agentapp/letmcletting/${state.user.ClientName}/appointments/${payload.appointmentID}/feedback/${payload.propertyID}/add`,
        headers: state.config.headers,
        params: {
          feedback: payload.feedbackText,
        },
      })
    },
    salesInstructionsDetails({ state }, OID) {
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/sales/instructions/${OID}`, state.config)
    },
    lettingPropertyDetails({ state }, OID) {
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/properties/${OID}`, state.config)
    },
    propertyTenanciesLatest({ state }, OID) {
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/properties/${OID}/tenancies/latest`, state.config)
    },
    getBuyerDetails({ state }, OID) {
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/contacts/people/buyers/${OID}`, state.config)
    },
    getTenantDetails({ state }, OID) {
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/contacts/people/applicants/${OID}`, state.config)
    },
    myTenancies({ state }, payload) { // calls all tenancies in portfolio
      return axios({
        method: 'GET',
        url: `/corporate/agentapp/letmcletting/${state.user.ClientName}/properties/tenancies/${payload.offset}/${payload.count}`,
        headers: state.config.headers,
        params: {
          minPrice: payload.minPrice,
          maxPrice: payload.maxPrice,
          minBeds: payload.minBeds,
          maxBeds: payload.maxBeds,
          propertyTypes: payload.propertyTypes,
        },
      })
    },
    myTenanciesPropertyTypes({ state, commit }) { // gets property type list to populate a select input list
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/properties/types`, state.config)
        .then(({ data }) => {
          commit('SET_PROPERTY_TYPES', data)
        })
    },
    mySales({ state }, payload) { //calls all sales properties in portfolio
      return axios({
        method: 'GET',
        url: `/corporate/agentapp/letmcletting/${state.user.ClientName}/sales/instructions/${payload.offset}/${payload.count}`,
        headers: state.config.headers,
        params: {
          minPrice: payload.minPrice,
          maxPrice: payload.maxPrice,
          minBeds: payload.minBeds,
          maxBeds: payload.maxBeds,
          propertyTypes: payload.propertyTypes,
        },
      })
    },
    getPropertyFeedback({ state }, payload) {
      return axios.get(`/corporate/agentapp/letmcletting/${state.user.ClientName}/appointments/${payload.appointmentOID}/feedback/${payload.propertyOID}`, state.config)
    },
    changePropertyType({ commit }, type) {
      commit('SET_PROPERTY_TYPE', type)
      return ''
    },
    // Message Requests -------------------------------------------------
    //-------------------------------------------------------------------
    getAllStaffEmails({ commit, dispatch }) {
      return axios.all([
        dispatch('Messages/getStaffEmails'),
        dispatch('Messages/getCompletedStaffEmails')
      ])
        .then(axios.spread((staff, completed) => {
          commit('SET_STAFF_EMAILS', {
            staff: staff,
            completed: completed
          })
      }))
    },
    getAllBranchEmails({ commit, dispatch }) {
      return axios.all([
        dispatch('Messages/getBranchEmails'),
        dispatch('Messages/getCompletedBranchEmails')
      ])
        .then(axios.spread((branch, completed) => {
          commit('SET_BRANCH_EMAILS', {
            branch: branch,
            completed: completed
          })
        }))
    },
    getAllOnlineApplications({commit, dispatch}) {
      return axios.all([
        dispatch('Messages/getStaffOnlineApplications'),
        dispatch('Messages/getCompletedStaffOnlineApplications'),
        dispatch('Messages/getBranchOnlineApplications'),
        dispatch('Messages/getCompletedBranchOnlineApplications'),
      ])
        .then(axios.spread( (staff, staffCompleted, branch, branchCompleted) => {
          commit('SET_ONLINE_APPLICATIONS', {
            staff: staff, 
            staffCompleted: staffCompleted,
            branch: branch,
            branchCompleted: branchCompleted
          })
        }))
    },
    getAllLoginRegistrations({ commit, dispatch }) {
      return axios.all([
        dispatch('Messages/getStaffOnlineLogins'),
        dispatch('Messages/getCompletedStaffOnlineLogins'),
        dispatch('Messages/getBranchOnlineLogins'),
        dispatch('Messages/getCompletedBranchOnlineLogins')
      ])
        .then(axios.spread((staff, staffCompleted, branch, branchCompleted) => {
          commit('SET_LOGIN_REGISTRATIONS', {
            staff: staff,
            staffCompleted: staffCompleted,
            branch: branch,
            branchCompleted: branchCompleted
          })
      }))
    },
    getAllSalesProperties({ commit, dispatch }) {
      return axios.all([
        dispatch('Messages/getStaffSalesProperties'),
        dispatch('Messages/getCompletedStaffSalesProperties'),
        dispatch('Messages/getStaffSalesProperties'),
        dispatch('Messages/getBranchSalesProperties'),
      ])
        .then(axios.spread((staff, staffCompleted, branch, branchCompleted) => {
          commit('SET_SALES_PROPERTIES', {
            staff: staff,
            staffCompleted: staffCompleted,
            branch: branch,
            branchCompleted: branchCompleted
          })
        }))
    },
    getAllLettingsProperties({ commit, dispatch }) {
      return axios.all([
        dispatch('Messages/getStaffLettingsProperties'),
        dispatch('Messages/getCompletedStaffLettingsProperties'),
        dispatch('Messages/getBranchLettingsProperties'),
        dispatch('Messages/getCompletedBranchLettingsProperties'),
      ])
        .then(axios.spread((staff, staffCompleted, branch, branchCompleted) => {
          commit('SET_LETTINGS_PROPERTIES', {
            staff: staff,
            staffCompleted: staffCompleted,
            branch: branch,
            branchCompleted: branchCompleted
          })
        }))
    },
    downloadMessageAttachment({ state }, attachmentID) {
      return axios({
        method: 'GET',
        url: `/corporate/agentapp/letmcletting/${state.user.ClientName}/intray/download/attachment/${attachmentID}`,
        responseType: 'blob',
        headers: {
          token: state.user.LoginToken,
          api_key: state.apiKey,
        },
      })
    },
  },
  getters: {
    filterTodaysAppointments: (state) => {
      return state.appointments.filter((app) => {
        return (
          app.start.substr(0, 10) == state.today.toISOString().substr(0, 10)
        )
      })
    }
  },
})
