/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:606c6299-08c6-4ae3-bf53-4a9d2935cfd8",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_zbnFmtqj4",
    "aws_user_pools_web_client_id": "762sk7ckact6uddpurismmqb0u",
    "oauth": {}
};


export default awsmobile;
