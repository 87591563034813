import axios from 'axios';

export default {
  namespaced: true,
  state: {
    selectedBuyer: '',
    selectedApplicant: ''
  },
  mutations: {
    SET_SELECTED_BUYER(state, data) {
      state.selectedBuyer = data
    },
    CLEAR_SELECTED_BUYER(state) {
      state.selectedBuyer = ''
    },
    SET_TENANCY_APPLICANT(state, data) {
      state.selectedApplicant = data
    },
    CLEAR_SELECTED_APPLICANT(state) {
      state.selectedApplicant = ''
    }
  },
  actions: {
    //Sales offer
    submitSalesOffer({ rootState }, offerObject) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/sales/instructions/offers/submit`,
        headers: rootState.config.headers,
        data: {
          SalesInstructionID: offerObject.SalesInstructionID,
          BuyerApplicantID: offerObject.BuyerApplicantID,
          OfferAmount: offerObject.OfferAmount,
          DepositType: offerObject.DepositType,
          FinancialVerified: offerObject.FinancialVerified,
          SubjectToContract: offerObject.SubjectToContract,
          SubjectToMortgage: offerObject.SubjectToMortgage,
          SubjectToSurvey: offerObject.SubjectToSurvey,
          FixturesAndFittings: offerObject.FixturesAndFittings,
          SpecialTerms: offerObject.SpecialTerms,
          InternalNotes: offerObject.InternalNotes,
          Documents: offerObject.Documents
        }
      })
    },
    createApplicantBuyer({ rootState }, applicantObject) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/sales/applicants/create`,
        headers: rootState.config.headers,
        data: {
          Title: applicantObject.Title,
          Forename: applicantObject.Forename,
          Surname: applicantObject.Surname,
          Email: applicantObject.Email,
          Mobile: applicantObject.Mobile,
          Landline: applicantObject.Landline,
          PreferredContactMethod: applicantObject.PreferredContactMethod,
        }
      })
    },
    submitTenancyApplication({ rootState }, payload) {
      const Applicant = {
        TenancyRef: payload.Applicant.TenancyRef,
        Title: payload.Applicant.Title,
        Forename: payload.Applicant.Forename,
        Surname: payload.Applicant.Surname,
        DOB: payload.Applicant.DOB,
        Nationality: payload.Applicant.Nationality,
        MobilePhone: payload.Applicant.MobilePhone,
        EmailAddress: payload.Applicant.EmailAddress,
        TenantType: payload.Applicant.TenantType,
        GeneralNotes: payload.Applicant.GeneralNotes,
        Reference: payload.Applicant.Reference,
      }
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/properties/tenancies/applications/submit`,
        headers: rootState.config.headers,
        params: {
          tenancyID: payload.tenancyID
        },
        data: {
          Applicant: Applicant,
          Documents: payload.Applicant.Documents
        }
      })
    },
  }
}