import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
// import debounce from 'lodash/debounce'
// import moment from 'moment'

import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
import { Amplify, API, Auth, Storage } from 'aws-amplify';
Amplify.register(API)
Amplify.register(Storage)
Amplify.register(Auth)
/* Register the services before configure */

Amplify.configure(aws_exports);

//axios.defaults.baseURL = `https://test2-api.letmc.com`
axios.defaults.baseURL = `https://live-api.letmc.com`

Vue.config.productionTip = false

Vue.filter('filterPriceGlobal', function (val) {
  if (!val) return ''
  return val.toLocaleString('en-UK', { style: 'currency', currency: 'GBP' })
})

new Vue({
  router,
  store,
  vuetify,
  // debounce,
  // moment,
  //sets user state to current localstorage in
  // case of refresh
  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_USER_DATA', userData)
    }

    // *** saftey measure to avoid use of fake tolkens
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  },
  render: (h) => h(App),
}).$mount('#app')
