import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('../views/Home.vue')
const NegApp = () => import('../views/DiaryApp.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/index.html',
    component: Home,
    alias: '/'
  },
  {
    path: '/negapp',
    redirect: '/negapp/dashboard',
    component: NegApp,
    meta: { requiresAuth: true},
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue'),
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: () => import('../views/Calendar.vue'),
      },
      {
        path: 'properties',
        name: 'properties',
        component: () => import('../views/Properties.vue'),

      },
      {
        path: 'messages',
        name: 'messages',
        component: () => import('../views/Messages.vue'),
      },
      {
        path: 'messages/registrations',
        name: 'registrations',
        component: () => import('../views/MessageViews/LoginRegistrations.vue'),
      },
      {
        path: 'messages/staff-emails',
        name: 'staffEmails',
        component: () => import('../views/MessageViews/StaffEmails.vue'),
      },
      {
        path: 'messages/branch-emails',
        name: 'branchEmails',
        component: () => import('../views/MessageViews/BranchEmails.vue'),
      },
      {
        path: 'messages/branch-sms',
        name: 'branchSms',
        component: () => import('../views/MessageViews/BranchSms.vue'),
      },
      {
        path: 'messages/online-applications',
        name: 'onlineApplications',
        component: () => import('../views/MessageViews/OnlineApplications.vue'),
      },
      {
        path: 'messages/sales-valuations',
        name: 'salesValuations',
        component: () => import('../views/MessageViews/SalesProperties.vue'),
      },
      {
        path: 'messages/lettings-valuations',
        name: 'lettingsValuations',
        component: () => import('../views/MessageViews/LettingsProperties.vue'),
      },
      {
        path: 'offer',
        name: 'salesOffers',
        component: () => import('../views/SalesOffers.vue'),
      },
      {
        path: 'buyer',
        name: 'createBuyer',
        component: () => import('../views/CreateBuyer.vue'),
      },
      {
        path: 'application',
        name: 'tenancyApplication',
        component: () => import('../views/TenancyApplication.vue'),
      },
      {
        path: 'buyers',
        name: 'myBuyers',
        component: () => import('../views/MyBuyers.vue'),
      },
      {
        path: 'applicants',
        name: 'myApplicants',
        component: () => import('../views/MyApplicants.vue'),
      },
      {
        path: 'images/:id',
        name: 'property-images',
        component: () => import('../views/PropertyImages.vue'),
        props: true
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/')
  }
  next()
})

export default router
