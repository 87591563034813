<template>
  <v-app>
    <!-- <TheNavbar v-if="$store.state.user" /> -->
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
// import TheNavbar from '@/components/TheNavbar'

window.zESettings = {
  webWidget: {
    color: {
      theme: "#FF69B4",
      launcher: "#CC3A83", // This will also update the badge
      launcherText: "#E589B7",
      button: "#8A0648",
      resultLists: "#691840",
      header: "#203D9D",
      articleLinks: "#FF4500",
    },
  },
};

export default {
  name: "App",
  components: {
    // TheNavbar,
  },
  data: () => ({
    //
  }),
  mounted() {
    console.log("*** The NegApp frontend by Claudio Rescigno ***");
    // $zopim(function(){
    //   $zopim.livechat.theme.setColor('#f26642')
    //   $zopim.livechat.window.setTitle('AgentOS Support')
    // })
  },
};
</script>

<style>
/* Global app styles */
.pointer {
  cursor: pointer;
}

#sticky-nav {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  width: 100%;
  z-index: 1 !important;
  border-bottom: 1px solid rgba(242, 101, 66, 0.37);
}

/* Transitions */
.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  transition: all 0.3s ease;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: all 0.3s ease;
}

/* Msg components */
.fade-slide-vertical-enter {
  transform: translateY(30px);
  opacity: 0;
}

.fade-slide-vertical-enter-active {
  transition: all 0.3s ease;
}

.fade-slide-vertical-leave-to {
  transform: translateY(60px);
  opacity: 0;
}

.fade-slide-vertical-leave-active {
  transition: all 0.3s ease;
}
</style>
