import axios from 'axios';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    //NB these actions are being dispatched from index.js. See e.g getAllStaffEmails

    //Staff msgs
    getStaffEmails({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/emails/staff/0/100`, rootState.config)
    },
    getCompletedStaffEmails({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/emails/staff/completed/0/100`, rootState.config)
    },
    //Sent msgs
    getSentMessages({ commit, rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/messages/staff/sent/0/100`, rootState.config )
        .then(({ data }) => {
          commit('SET_SENT_MESSAGES', data, {root: true})
        })
    },
    //Branch msgs
    getBranchEmails({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/emails/branch/0/100`, rootState.config)
    },
    getCompletedBranchEmails({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/emails/branch/completed/0/100`, rootState.config)
    },
    //Branch sms/text
    getBranchSms({ rootState, commit }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/sms/branch/0/100`, rootState.config)
      .then(({ data }) => {
        commit('SET_BRANCH_SMS', data, {root: true})
      })
    },
    // Online applications
    getStaffOnlineApplications({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/tenancyapplications/staff/0/100`, rootState.config)
    },
    getCompletedStaffOnlineApplications({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/tenancyapplications/staff/completed/0/100`, rootState.config)
    },
    getBranchOnlineApplications({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/tenancyapplications/branch/0/100`, rootState.config)
    },
    getCompletedBranchOnlineApplications({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/tenancyapplications/branch/completed/0/100`, rootState.config)
    },
    // Login registrations
    getStaffOnlineLogins({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/onlinelogins/staff/0/100`, rootState.config)
    },
    getCompletedStaffOnlineLogins({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/onlinelogins/staff/completed/0/100`, rootState.config)
    },
    getBranchOnlineLogins({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/onlinelogins/branch/0/100`, rootState.config)
    },
    getCompletedBranchOnlineLogins({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/onlinelogins/branch/completed/0/100`, rootState.config)
    },
    // Sales valuations (properties)
    getStaffSalesProperties({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/salesrequests/staff/0/100`, rootState.config)
    },
    getCompletedStaffSalesProperties({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/salesrequests/staff/completed/0/100`, rootState.config)
    },
    getBranchSalesProperties({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/salesrequests/branch/0/100`, rootState.config)
    },
    getCompletedBranchSalesProperties({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/salesrequests/branch/completed/0/100`, rootState.config)
    },
    // Lettings valuations (properties)
    getStaffLettingsProperties({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/tenancyrequests/staff/0/100`, rootState.config)
    },
    getCompletedStaffLettingsProperties({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/tenancyrequests/staff/completed/0/100`, rootState.config)
    },
    getBranchLettingsProperties({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/tenancyrequests/branch/0/100`, rootState.config)
    },
    getCompletedBranchLettingsProperties({ rootState }) {
      return axios.get(`/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/tenancyrequests/branch/completed/0/100`, rootState.config)
    },
    // Toggle read/unread multiple msgs
    toggleMessageIsRead({ rootState }, payload) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/read`,
        headers: rootState.config.headers,
        params: {
          messageIDs: payload
        }
      })
    },
    //Archive multiple msg
    archiveMultipleMessages({ rootState }, payload) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/archive`,
        headers: rootState.config.headers,
        params: {
          messageIDs: payload
        }
      })
    },
    // Complete multiple msgs
    completeMultipleMessages({ rootState }, payload) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/complete`,
        headers: rootState.config.headers,
        params: {
          messageIDs: payload
        }
      })
    },
    //Fetch sms and email accounts
    getLoggedInUserEmailAccount({ commit, rootState }) {
      return axios({
        method: 'GET',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/accounts/email`,
        headers: rootState.config.headers,
      })
        .then(({ data }) => {
          // console.log(data)
          commit('SET_LOGGED_IN_USER_EMAIL_ACCOUNTS', data.Data, {root: true})
        })
    },
    getLoggedInUserSmsAccount({ commit, rootState }) {
      return axios({
        method: 'GET',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/accounts/text`,
        headers: rootState.config.headers,
      })
        .then(({ data }) => {
          commit('SET_LOGGED_IN_USER_SMS_ACCOUNTS', data.Data, {root: true})
        })
    },
    //Reply
    sendEmailReply({ rootState }, payload) {
      // const data = payload.content
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/emails/${payload.originalMessageID}/reply`,
        headers: rootState.config.headers,
        data: {
          MessagingEmailAccountID: payload.messagingEmailAccountID,
          Content: payload.content,
          To: payload.to,
          CC: payload.cc,
          BCC: payload.bcc
        },
      })
    },
    sendEmail({ rootState }, payload) {
      // const data = payload.content
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/emails/send`,
        headers: rootState.config.headers,
        data: {
          MessagingEmailAccountID: payload.messagingEmailAccountID,
          Subject: payload.subject,
          Content: payload.content,
          To: payload.to,
          CC: payload.cc,
          BCC: payload.bcc
        },
      })
    },
    sendSmsReply({ rootState }, payload) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/sms/${payload.originalMessageID}/reply`,
        headers: rootState.config.headers,
        data: {
          Content: payload.content,
          TextAccountID: payload.textAccountID
        },
      })
    },
    sendSms({ rootState }, payload) {
      return axios({
        method: 'POST',
        url: `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/intray/sms/send`,
        headers: rootState.config.headers,
        data: {
          Content: payload.content,
          TextAccountID: payload.textAccountID,
          To: payload.to
        },
      })
    },
  },
  getters: {}
}