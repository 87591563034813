// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        // primary: '#F26642',
        primary: '#eb5c37',
        // secondary: '#343351',
        secondary: '#343351',
        accent: '#8c9eff',
        error: '#ff0000',
      },
    },
  },
}

export default new Vuetify(opts)
