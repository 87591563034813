import axios from 'axios'

export default {
  namespaced: true,
  state: {
    allDiaries: '',
    propertyToBook: ''
  },
  mutations: {
    SET_ALL_DIARIES(state, data) {
      state.allDiaries = data
    },
    ADD_MY_DIARY(state, data) {
      state.allDiaries.unshift(data)
    },
    SET_PROPERTY_TO_BOOK(state, data) {
      state.propertyToBook = data
    },
    CLEAR_PROPERTY_TO_BOOK(state) {
      state.propertyToBook = ''
    }
      
  },
  actions: {
    //Get all diaries
    listAllDiaries({ rootState, commit }) {
      return axios.get(
        `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/diary/diaries`,
        rootState.config
      )
        .then(({ data }) => {
          commit('SET_ALL_DIARIES', data.Data)
          // ADD LOGGED IN USER DIARY TO LIST
          const myDiary = {
            DiaryName: '',
            StaffID: rootState.sessionData.OID,
            StaffName: rootState.sessionData.ActiveUser
          }
          commit('ADD_MY_DIARY', myDiary)
        })
      .catch(err => console.log(err))
    },
    getColleaguesDiary({ rootState, commit }, diaryObject) { // fetching all appoinments from a colleague`s diary
      //setting date range for appointments
      let date = rootState.date //start date
      date.setDate(date.getDate() - 90) // minus 90 days
      let pastDate = date
        .toISOString()
        .substr(0, 10)

      let date2 = rootState.date2 // end date
      date2.setDate(date2.getDate() + 1095) // plus 1095 days
      let futureDate = date2
        .toISOString()
        .substr(0, 10)

      return axios
        .get(
          `/corporate/agentapp/letmcletting/${rootState.user.ClientName}/diary/appointments?startDate=${pastDate}&endDate=${futureDate}&diaryName=${diaryObject.DiaryName}`,
          rootState.config
        )
        .then(({ data }) => {
          // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          let newArray = data.map((obj) => {
            return {
              ...obj,
              end: obj.End,
              start: obj.Start,
              name: obj.Subject,
              myCalendar: false, //setting appointments as colleague`s
              colleagueName: diaryObject.StaffName,
              color: diaryObject.color
            }
          })
          commit('SET_COLLEAGUES_APPOINTMENTS', newArray, { root: true })
        })
    }
  },
}
